import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { ALL_EVENTS_URL, createQrs, sendQrs } from './api-calls/requests';
import { useNavigate } from 'react-router-dom';

// const ALL_EVENTS_URL = 'http://127.0.0.1:8000/api/all-events';  //For dev
// const ALL_EVENTS_URL = 'https://api.buradago.com/public/api/all-events';  //For p

const PaymentReversal = ({ data }) => {

const [events, setEvents] = useState([]);

const navigateThere = useNavigate();

const getAllEvents = async ()=>{

  try {

    // await axios.get('https://api.buradago.com/public/sanctum/csrf-cookie') //production
    // await axios.get('http://127.0.0.1:8000/sanctum/csrf-cookie');  //development]
    const response = await axios.get(ALL_EVENTS_URL,
        // JSON.stringify({ email,password }),
        {
            headers: { 'Content-Type': 'application/json',
          'Accept':'application/json',
          // 'Access-Control-Allow-Credentials':true
         },
            withCredentials: true
        }
    );

    if (response?.data){
      setEvents(response?.data)
    }

} catch (err) {
    if (!err?.response) {
      console.log('There was an error');
    } else if (err.response?.status === 409) {
      console.log('There was an error');
    } else {
      // console.log(JSON.stringify({ username,password}));
    }
    // errRef.current.focus();
}
}


useEffect(
  ()=>{getAllEvents();},[]
);

 
  return (
    <div className="mt-[3rem] ">
      <Table striped bordered hover className=' table'>
        <thead>
          <tr>
            <th>Kod</th>
            <th>Etkinlik Adı</th>
            <th>Fenomen Adı</th>
            <th>Etkinlik Tarihi</th>
            <th>Etkinlik Mekani</th>
            <th className='bg-red-300'>Eylemler</th>
          </tr>
        </thead>
        <tbody>
          {events.map((item, index) => (
            <tr key={index}>
              <td className=''>{item.id}</td>
              <td>{item.etkinlik_adi}</td>
              <td>{item.fenomen_id}</td>
              <td>{item.etkinlik_tarihi}</td>
              <td>{item.etkinlik_mekan}</td>
              <td>
                <span className='bg-green-300 rounded-5 cursor-pointer p-1 text-xs mx-2 '
                 onClick={()=>{navigateThere('/approved-ticket-buyers',{'state':{'event_id':item.id}})}}

                >Hak Kazanan</span>
                <span className='bg-red-200 cursor-pointer p-1 rounded-5 text-xs mx-2'
                onClick={()=>{navigateThere('/to-reverse',{'state':{'event_id':item.id}})}}
                >Iade Edilecekler</span>

                <span className='bg-red-200 cursor-pointer p-1 rounded-5 text-xs mx-2' 
                onClick={()=>{navigateThere('/refunded',{'state':{'event_id':item.id}})}}
                >Iade Edilen</span>
                </td>
                
            </tr>
          ))}
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          
          
          
          
          {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
          {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

        </tbody>
      </Table>
    </div>
  );
};

export default PaymentReversal;
